<template>
	<article class="view-user-profile">
		<div class="site-wrapper rythm-v-l">
			<header>
				<h1>{{ $t("Mon compte") }}</h1>
			</header>

			<section class="section--profile card shadow rythm-v"
							 v-if="userData">
				<h2>{{ $t("Informations professionnelles") }}</h2>

				<form method="post"
							@submit.prevent="updateUserData">
					<div class="flex-row flex-center-v">
						<div class="flex-item--30">
							<div class="input-group ">
								<label for="firstname">{{ $t("Prénom") }}</label>
								<input type="text"
											 name="firstname"
											 id="firstname"
											 v-model="user.firstname"
											 disabled />
							</div>
						</div>

						<div class="flex-item--30">
							<div class="input-group ">
								<label for="lastname">{{ $t("Nom") }}</label>
								<input type="text"
											 name="lastname"
											 id="lastname"
											 v-model="user.lastname"
											 disabled />
							</div>
						</div>

						<div class="flex-item--30">
							<div class="input-group">
								<label for="email">{{ $t("Adresse e-mail") }}</label>
								<input type="text"
											 name="email"
											 id="email"
											 v-model="user.email"
											 disabled />
							</div>
						</div>
					</div>

					<div class="flex-row">
						<div class="flex-item--30">
							<div class="input-group">
								<label for="city">{{ $t("Lieu") }}</label>
								<input type="text"
											 name="city"
											 id="city"
											 v-model="userData.city"
											 disabled />
							</div>
						</div>

						<div class="flex-item--30">
							<div class="input-group">
								<label for="zip">{{ $t("Code postal") }}</label>
								<input type="text"
											 name="zip"
											 id="zip"
											 v-model="userData.zip"
											 disabled />
							</div>
						</div>

						<div class="flex-item--30">
							<div class="input-group ">
								<label for="country">{{ $t("Pays") }}</label>
								<input type="text"
											 name="country"
											 id="country"
											 v-model="userData.country"
											 disabled />
							</div>
						</div>


					</div>

					<div class="flex-row">

						<div class="flex-item--25 flex-item--grow">
							<label class="label">{{ $t("Spécialité") }}</label>
							<div class="input-group">
								<select name="specialty"
												id="specialty"
												disabled
												aria-readonly="true">
									<option value="">{{ $t("Aucune sélection") }}</option>
									<option v-for="specialty in lists.specialties"
													:key="`specialty_${specialty.id}`"
													:value="specialty.id"
													:selected="specialty.id === userData.specialty_id">{{
														$t(specialty.name)
													}}</option>
								</select>
							</div>
						</div>

						<div class="flex-item--25 flex-item--grow">
							<div class="input-group">
								<label for="lang">{{ $t("Langue") }}</label>
								<input type="text"
											 name="lang"
											 id="lang"
											 :value="$t(user.lang)"
											 disabled />
							</div>
						</div>

						<!-- <div class="flex-item--25 flex-item--grow">
              <p class="label required">{{ $t("Type d'exercice") }}</p>
              <div class="input-group">
                <select name="practices" id="practices" @change="setPractice" required>
                  <option value="">{{ $t("Aucune sélection") }}</option>
                  <option v-for="(practice, index) in lists.practices" :key="index" :value="practice" :selected="practice === userData.practice">{{ $t(practice) }}</option>
                </select>
              </div>
            </div> -->

						<!-- <div class="flex-item--25 flex-item--grow" v-if="this.userData.practice === 'Autre'">
              <div class="input-group required">
                <label for="practice_other">{{ $t("Autre type d’exercice") }}</label>
                <input type="text" name="practice_other" id="practice_other" v-model="userData.practice_other" required />
              </div>
            </div> -->

						<!-- <div class="flex-item--25">
              <p class="label">{{ $t("Catégorie d’âge") }}</p>
              <div class="input-group">
                <select name="age" id="age" @change="setAge">
                  <option value="">{{ $t("Aucune sélection") }}</option>
                  <option v-for="(age, index) in lists.age" :key="index" :value="age" :selected="age === userData.age">{{ age }}</option>
                </select>
              </div>
            </div> -->

						<div class="flex-item--25 flex-item--grow flex-row flex-align-top avatar-wrapper">
							<div class="input-group input-avatar">
								<label for="avatar"
											 :class="validations.avatar.invalid ? 'invalid' : ''">{{ $t("Photo / Avatar") }} <small>{{ validations.avatar.error }}</small></label>
								<input type="file"
											 name="avatar"
											 id="avatar"
											 ref="avatar"
											 accept="image/*"
											 :invalid="validations.avatar.invalid"
											 @change="setAvatarPreview" />
							</div>
							<img v-if="avatarPreviewSrc && !validations.avatar.invalid"
									 :src="avatarPreviewSrc"
									 class="avatar-preview"
									 alt="Avatar"
									 width="128"
									 height="auto" />
						</div>

						<div class="flex-item">
							<div class="input-group--inline input-group-checkbox">
								<label for="email-consent"
											 style="white-space: break-spaces;">
									<input type="checkbox"
												 id="email-consent"
												 name="email-consent"
												 v-model="userData.email_consent" />
									<span class="caption">{{ $t("J'accepte de recevoir des e-mails de la plateforme (Questions de la semaine, notifications)") }}</span>
								</label>
							</div>
						</div>

						<div class="input-group--controls flex-item--100">
							<button type="submit"
											class="button--secondary">
								<svg class="icon icon-check"
										 role="img"
										 xmlns="http://www.w3.org/2000/svg"
										 width="18"
										 height="13"
										 fill="none"
										 viewBox="0 0 18 13">
																		                  <path
																		                    fill="currentColor"
																		                    fill-rule="evenodd"
																		                    d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
																		                    clip-rule="evenodd"
																		                  />
																		                </svg>
								<span class="caption">{{ $t("Enregistrer") }}</span>
							</button>
						</div>
					</div>
				</form>
			</section>
		</div>
	</article>
</template>

<script>
import { timestamp_format_human } from "../libs/helpers";
import countries from "../libs/countries.json";

// Dashboard component
export default {
	name: "userProfile",
	props: ["userProfileID"],

	data: function () {
		return {
			// User profile
			pro_id: "",
			userData: null,

			paymentMethod: "",

			// Avatar
			fileInput: null,
			avatarPreviewSrc: "",

			// radio/selects lists
			lists: {
				languages: this.$listLanguages(),
				countries: countries,
				specialties: [],
			},

			// Validations
			validations: {
				avatar: {
					invalid: false,
					error: this.$t("(1 Mo max.)"),
				},
			},
		};
	},

	computed: {
		// Current user data
		user: function () {
			return this.$store.state.userData;
		},
	},

	methods: {
		// fetch specialties
		async getSpecialties() {
			return await this.$store.dispatch("GET_SPECIALTIES");
		},

		// Load user data from database
		refreshUser: function () {
			if (this.userData && this.userData.id && this.userData.id === this.user.id) {
				this.$store.dispatch("REFRESH_USER", this.userData.id).then((response) => {
					this.userData = response;
				});
			}
		},

		// Check if all validation object "checked" properties are true
		formValidates: function (validations) {
			let trues = 0;
			validations.forEach((el) => {
				if (el.checked) trues++;
			});
			return trues === validations.length;
		},

		// Format Unix Timestamp
		timestampToDate: function (timestamp) {
			let localDate = timestamp_format_human(timestamp, {
				year: "numeric",
				month: "2-digit",
				day: "numeric",
			});
			return localDate;
		},

		// set language from select
		setLanguage(e) {
			this.userData.lang = e.target.value;
			// Save lang to store
			// this.$store.dispatch("LANG", this.userData.lang);
			// Apply language?
			// this.$loadLanguageAsync(this.userData.lang);
		},

		// set practice from select
		// setPractice(e) {
		// 	this.userData.practice = e.target.value;
		// 	if (this.userData.practice !== "Autre") this.userData.practice_other = "";
		// },

		// set age from select
		// setAge(e) {
		// 	this.userData.age = e.target.value;
		// },

		// display avatar preview
		setAvatarPreview(e) {
			let fileInput = e.target;
			if (fileInput.files && fileInput.files[0]) {
				this.validations.avatar.invalid = false;
				this.validations.avatar.error = this.$t("(1 Mo max.)");

				// image preview
				let blob = URL.createObjectURL(fileInput.files[0]);
				if (blob) this.avatarPreviewSrc = blob;

				// file type
				if (fileInput.files[0].type.indexOf("image/") === -1) {
					this.validations.avatar.invalid = true;
					this.validations.avatar.error = this.$t("(Le fichier n'est pas une image)");
				}

				// file weight
				if (typeof FileReader !== "undefined") {
					let size = fileInput.files[0].size;
					if (size > 1000000) {
						this.validations.avatar.invalid = true;
					}
				}
			}
		},

		// Update profile
		updateUserData: function () {
			let userID = this.userProfileID;
			// this.userData.id = userID;

			let fileInput = this.$refs.avatar.files[0];
			let formData = new FormData();

			if (!!fileInput) formData.append("file", fileInput);
			formData.append("data", JSON.stringify(this.userData));

			this.$store
				.dispatch("UPDATE_USER_DATA", { userID, formData })
				.then(() => {
					this.loadUserData();

					this.$toasted.clear();
					return this.$toasted.global.appSuccess({
						message: this.$t("Vos informations ont été mise à jour."),
					});
				})
				.catch((error) => {
					this.$toasted.clear();
					return this.$toasted.global.appError({
						message: this.$t("Une erreur est survenue lors de l'enregistrement de votre profil.<br>Veuillez nous contacter pour résoudre le problème."),
					});
				});
		},

		// Load user data from database
		loadUserData: function () {
			this.$store
				.dispatch("GET_USER_DATA", this.userProfileID)
				.then((response) => {
					this.userData = response;
					// Set language
					// this.$loadLanguageAsync(this.userData.lang);
					// Set avatar preview
					this.avatarPreviewSrc = this.userData.avatar;
				})
				.catch((error) => {
					console.warn(error);
				});
		},
	},

	async mounted() {
		this.lists.specialties = await this.getSpecialties();

		// NOTE: Get the `userProfileID` parameter value from the component's props or this.$route.params.userProfileID
		// NOTE: Only admins can view another user profile: Check if the current user ID (this.user.id) is different of the user profile id (userProfileID)
		this.loadUserData();
	},
};
</script>

<style lang="scss" scoped>
@import "src/scss/register-profile.scss";

[role="alert"]>*+* {
	padding-left: 0.5em;
}

pre {
	white-space: pre-line;
	background: white;
	border: 1px solid #efefef;
	border-radius: 6px;
	padding: 1em;
}

.form-validations {
	margin-top: 1rem;
}

.section--email .form-validations {
	margin-bottom: 1rem;
	margin-top: 0;
	margin-left: 1rem;
}

.input-group--controls {
	&.controls--payment {
		button {
			margin: 0.5rem;
			text-align: left;
		}
	}
}

.checkbox-details {
	padding-left: 1.5rem;
}

input:not(:checked)+label+.checkbox-details {
	@include offscreen();
}

input:checked+label {
	font-weight: 600;
}

@media all and (max-width: 1024px) {
	[class*="flex-item"].flex-item--25 {
		flex-basis: 50% !important;
	}
}

@media all and (max-width: 768px) {

	[class*="flex-item"].flex-item--25,
	[class*="flex-item"].flex-item--30 {
		flex-basis: 100% !important;
	}

	.section--email .form-validations {
		margin-bottom: 1rem;
		margin-top: 0;
		margin-left: 1rem;
	}
}
</style>
